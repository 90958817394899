import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { apiCall } from "../../api";

class MenuBoite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
      reload: this.props.reload
    };
  }

  componentDidMount() {
    this.getMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reload !== this.props.reload) {
      this.getMenu();
    }
  }

  getMenu = async () => {
    let data = await apiCall("get", "/api/menu?type=boite");
    this.setState({
      menu: data,
      reload: false
    });
  };

  removeItem = async id => {
    await apiCall("delete", `/api/menu?id=${id}`);
  };

  render() {
    let { menu } = this.state;

    let parsedMenu = {};
    parsedMenu.sandwich = [];
    parsedMenu.salade = [];
    parsedMenu.rechauffe = [];
    parsedMenu.accompagnement = [];

    menu.map(m =>
      parsedMenu[m.category].push(
        <li key={menu.indexOf(m)}>
          {this.props.isAutenticated ? (
            <FontAwesomeIcon
              onClick={() => this.removeItem(m._id)}
              icon="times"
            />
          ) : null}
          <div className="menu-item-head">
            <h4>{m.name}</h4>
            <h5>{m.price}</h5>
          </div>
          <p>{m.description}</p>
        </li>
      )
    );

    return (
      <div
        className="menu-bols"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <p className="menu-bot-intro">Tous nos bols sont des menus froids</p>

        <p className="menu-bot-intro">Des bols différents sont disponibles au comptoir tous les jours *</p>

        <img style={{ width: '90%' }} className="photo-bols" src="https://nikoli.s3.ca-central-1.amazonaws.com/visual/Bols-Corpo-onepager_2025.jpg" />
        {/* <img className='photo-bols' src='https://nikoli.s3.ca-central-1.amazonaws.com/visual/Bols-Corpo-onepager-2-min.jpg'/> */}

        {/* <div className="menu-cat-cont">
          <h3>Salade repas</h3>
          <ul>{parsedMenu.salade}</ul>
        </div>

        <div className="menu-cat-cont">
          <h3>Sandwich + salade</h3>
          <ul>{parsedMenu.sandwich}</ul>
        </div>

        <div className="menu-cat-cont">
          <h3>Salades d'accompagnement</h3>
          <ul>{parsedMenu.accompagnement}</ul>
        </div>

        <div className="menu-cat-cont">
          <h3>Réchauffe-moi</h3>
          <ul>{parsedMenu.rechauffe}</ul>
        </div> */}

        <p className="menu-cat-details">* Pour la livraison, un minimum de 20 bols est requis</p>
      </div>
    );
  }
}

export default MenuBoite;
